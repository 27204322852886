import React from "react";
import ImageTop from "../ImageTop/ImageTop";
import moment from "moment";

const Featured = ({
  groupText = "",
  duration = "00:20",
  time,
  url,
  title,
  img,
  remark,
  downloadURL,
  id,
  showHorizontalText,
  isLastInGroup,
  isFirstSlideWithoutText,
  filename,
  catId,
  flag = "",
  isLastInSlider = false,
  isFirstInSlider = true,
  fileId,
  category,
  date,
  categoryGroup = null,
  expDate = null,
}) => {
  return (
    <div className="featured_container">
      <div
        className={`flex featured ${groupText.toLowerCase()} ${
          showHorizontalText || isFirstInSlider ? "first" : ""
        } ${isLastInSlider || isLastInGroup ? "last" : ""}`}
      >
        {showHorizontalText && (
          <div className={`horizontal-text ${groupText.toLowerCase()}`}>
            {groupText}
          </div>
        )}

        <ImageTop
          style={`margin-sm ${isFirstSlideWithoutText ? "mm" : ""}`}
          key={id}
          id={id}
          flag={flag}
          date={date}
          duration={duration}
          title={title}
          img={img}
          time={time}
          url={url}
          remark={remark}
          isYesterdayOrOlder={false}
          downloadURL={downloadURL}
          filename={filename}
          catId={catId}
          fileId={fileId}
          category={category}
          groupText={groupText}
          categoryGroup={categoryGroup}
          expDate={expDate}
        />
      </div>
    </div>
  );
};

export default Featured;
