import React, { useEffect, useRef, useState } from "react";
import Welcome from "../components/Welcome/Welcome";
import axios from "axios";
import BasicView from "../components/BasicView/BasicView";
import MultipleCategories from "../components/MultipleCategories/MultipleCategories";
import Carousel from "../components/Carousel/Carousel";
import HaveABiggerOne from "../components/HaveABiggerOne/HaveABiggerOne";
import Navbar from "../components/Navbar/Navbar";
import MultipleCategoriesImg from "../components/MultipleCategoriesImg/MultipleCategoriesImg";
import Skeleton from "react-loading-skeleton";
import FloatingPlayer from "../components/FloatingPlayer/FloatingPlayer";
import ImageTop from "../components/ImageTop/ImageTop";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../hooks/useNavStore";
import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../authConfig";
import { fetchData, refreshToken } from "../helper/fetchData";
import Featured from "../components/Featured/Featured";
import FeaturedCarousel from "../components/FeaturedCarousel/FeaturedCarousel";
import BasicPlayer from "../components/BasicPlayer/BasicPlayer";
import moment from "moment";
import RecentPlayer from "../components/RecentPlayer/RecentPlayer";
import RecentList from "../components/RecentList/RecentList";

import Pagination from "../components/Pagination/Pagination";
import Modal from "../components/Modal/Modal";

import useWebSocket from "react-use-websocket";
import {
  adjustFeaturedData,
  breakpointsConfig,
  generateRandomId,
  SOCKET_URL,
} from "../helper/const";

import { throttle } from "lodash";
import Footer from "../components/Footer/Footer";

import { useIdleTimer } from "react-idle-timer";

const Homepage = () => {
  // fetch all data
  const { instance } = useMsal();
  const activeAccount = instance?.getActiveAccount() || null;
  const { setCurrentAudio } = usePlayerStore();
  const { setUsername, usernameGlobal } = useLoginStore();

  const {
    setEmailAddress,
    emailAddress,
    setIsLogin,
    isLogin,
    act,
    setIsNewspool,
    setAct,
    setWsGlobal,
  } = useDataStore();

  const { tokenZ, setTokenGlobal } = useLoginStore();

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);
  const [wss, setWss] = useState(null);

  const [blocks, setBlocks] = useState([]);
  const [loadTrending, setLoadTrending] = useState(true);
  const [loadWelcome, setLoadWelcome] = useState(true);
  const [loadFeatured, setLoadFeatured] = useState(true);
  const [trending, setTrendings] = useState([]);
  const [isIdle, setIsIdle] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { sendMessage, lastMessage, readyState } = useWebSocket(SOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
    heartbeat: {
      message: "ping",
      returnMessage: "pong",
      interval: 2 * 60 * 1000,
      timeout: 10 * 60 * 1000,
    },
  });

  const connectWs = (params) => {
    let action = "register";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        pageURL: window.location.href,
        userName: usernameGlobal,
        email: emailAddress,
        networkCode: "BLR",
        computerName: "PC-01",
        isLogin,
        customerCity: tokenZ?.customerCity || null,
        customerName: tokenZ?.customerName || null,
        officeLogin: tokenZ?.officeLogin || false,
      },
    };
    sendMessage(JSON.stringify(data));
  };

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    // sendMessage(JSON.stringify(data));
    setAct(JSON.stringify(data));
  };

  const readyStateString = {
    0: "CONNECTING",
    1: "OPEN",
    2: "CLOSING",
    3: "CLOSED",
  }[readyState];

  useEffect(() => {
    const updateWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    if (!usernameGlobal) return;
    if (!emailAddress) return;

    console.log(emailAddress, "email");

    if (readyStateString === "OPEN") {
      connectWs();
    }
  }, [readyStateString, RID, usernameGlobal, emailAddress, isLogin, tokenZ]);

  useEffect(() => {
    if (lastMessage) {
      const msg = JSON.parse(lastMessage.data) || "";
      const data = msg.data;
      const action = msg.action;

      if (data) {
        // const {changes, ...rest} = data

        setWss({
          action,
          ...data,
        });
      }
    }
  }, [lastMessage]);

  const closeModal = () => {
    setShow25(false);
    // setCurrentAudio(null)
  };

  const [list, setList] = useState([]);

  const [data, setData] = useState([]);

  const [featured, setFeatured] = useState([]);
  const [sLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPlayer, setScrolled200px] = useState(false); // Track 200px scroll

  const [arrOfImages, setArrOfImages] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const {
    setSearchGlobal,
    searchGlobal,
    searchFloating,
    textOnly,
    show25,
    setShow25,
    setDataIsNew,
    setSearchFloating,
  } = useDataStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [noFound, setNoFound] = useState(false);

  const updateBlocks = (newData) => {
    setBlocks((prevBlocks) =>
      prevBlocks.map((block) => {
        // Find the matching new data based on column_category_id
        const updatedData = newData.find(
          (data) => data.column_category_id === block.column_category_id
        );

        // If matching data is found, replace the old data with new data
        if (updatedData) {
          return {
            ...block,
            data: updatedData.data,
            isNew: new Date().toISOString(),
          };
        }
        return block; // If no match, keep the block unchanged
      })
    );
  };

  const mapData = (blocks) => {
    const groupedBlocks = {};

    blocks.forEach((item) => {
      if (!groupedBlocks[item.block_order]) {
        groupedBlocks[item.block_order] = [];
      }
      groupedBlocks[item.block_order].push(item);
    });

    const result = Object.values(groupedBlocks).map((group) => {
      return group.length === 1 ? group[0] : group;
    });

    const newObject = { category_name: "featured" };
    const anotherObject = { category_name: "trending" };

    result.unshift(newObject); // inserts at the beginning
    // result.splice(1, 0, anotherObject); // inserts at the second position

    return result;
  };

  const fetchWelcome = () => {
    const url = `https://fra-as-api.radio.cloud/v1/welcomePageVReadyTable`;
    // const url = `https://fra-as-api.radio.cloud/v1/welcomePageV2`

    const params = {
      customer_id: tokenZ.userId,
    };

    setLoadWelcome(true);

    fetchData(url, params, tokenZ.access_token)
      .then((data) => {
        const blocks = data || [];

        const datas = blocks
          .map((e) => e.data)
          .flat()
          .filter((e) => e.image) // Filter only elements with images
          .reduce((uniqueImages, currentImage) => {
            // Check if the URL already exists in the Set
            if (
              !uniqueImages.some((image) => image.image === currentImage.image)
            ) {
              uniqueImages.push(currentImage); // Add if unique
            }
            return uniqueImages;
          }, []);

        setArrOfImages(datas);

        setBlocks(blocks);

        setTimeout(() => {
          setLoadWelcome(false);
        }, 100);

        // setData(result);
      })
      .catch((error) => console.error("Error:", error))
      .finally((data) => setLoading(false));
  };

  const fetchTrending = () => {
    return;
    const url = `https://fra-as-api.radio.cloud/v1/trendingReadyTable`;
    // const url = `https://fra-as-api.radio.cloud/v1/welcomePageV2`

    const params = {
      customer_id: tokenZ.userId,
    };
    setLoadTrending(true);

    fetchData(url, params, tokenZ.access_token)
      .then((data) => {
        console.log(data, "<<data trending");
        setTrendings(data);
        setLoadTrending(false);

        // setData(result);
      })
      .catch((error) => console.error("Error:", error))
      .finally((data) => setLoading(false));
  };

  useEffect(() => {
    if (!tokenZ) return;
    if (!ridLocal) {
      const rid = generateRandomId();
      setRID(rid);

      localStorage.setItem("rid", rid);
    }

    fetchWelcome();
    fetchTrending();
  }, [tokenZ]);

  useEffect(() => {
    if (blocks.length > 0) {
      const data = mapData(blocks);
      setData(data);
    }
  }, [blocks]);

  const fetchFeatured = () => {
    // const url = `https://fra-as-api.radio.cloud/v1/featuredData`
    const url = `https://fra-as-api.radio.cloud/v1/featuredDataReadyTable`;

    const params = {
      customer_id: tokenZ.userId,
    };

    setLoadFeatured(true);

    fetchData(url, params, tokenZ.access_token)
      .then((data) => {
        let featuredData = data.flatMap((item) =>
          item.data.map((subItem) => ({
            ...subItem,
            grouptext: item.grouptext,
            flag: item.flag,
          }))
        );
        setFeatured(featuredData);

        setLoadFeatured(false);
      })
      .catch((error) => console.error("Error:", error))
      .finally((data) => setLoading(false));
  };

  useEffect(() => {
    if (!tokenZ) return;

    fetchFeatured();
  }, [tokenZ]);

  const login = async (username, password, token) => {
    const url =
      "https://drq39eb793.execute-api.eu-central-1.amazonaws.com/v1/auth";

    setEmailAddress(username);

    try {
      const response = await axios.post(
        url,
        { username, password, ashopNew: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setIsLogin(true);
      setTokenGlobal(response.data);
      setUsername(response.data.userName);
    } catch (error) {
      console.error("Login failed:", error);
      throw error; // Throwing error to handle it later if needed
    }
  };

  const hasFetchedToken = useRef(false); // Track if API was called

  useEffect(() => {
    if (tokenZ) return;

    const fetchToken = async () => {
      if (activeAccount && !hasFetchedToken.current) {
        hasFetchedToken.current = true; // Mark as fetched after successful login

        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: activeAccount,
          });

          const username = response.account.username;
          const idToken = response.idToken;

          await login(username, undefined, idToken);
        } catch (error) {
          console.error("Token acquisition failed:", error);
        }
      }
    };

    fetchToken();
  }, [instance, activeAccount, tokenZ]);

  const searchAudioShop = (searchQuery, pageNumber) => {
    setSearchLoading(true);
    const params = {
      searchQuery,
      pageNumber,
      rowPerPage: 50,
    };

    const url = `https://fra-as-api.radio.cloud/v1/searchFiles`;

    fetchData(url, params, tokenZ.access_token)
      .then((data) => {
        setSearchResults(data.data);
        setSearchLoading(false);
        setTotalPages(data.pagination.totalPages);

        activityWs(`Search: ${searchQuery}`);

        setNoFound(true);
      })
      .catch((err) => {
        setSearchLoading(false);
      });
  };

  const searchRef = useRef(null);

  useEffect(() => {
    const theText = searchGlobal || searchFloating;

    setNoFound(false);
    if (searchRef.current === theText) {
      if (theText) {
        searchAudioShop(theText, currentPage);
      }
      return;
    }

    if (!theText) {
      setSearchResults([]);
    }

    const handler = setTimeout(() => {
      if (theText) {
        searchAudioShop(theText, currentPage);
        searchRef.current = theText;
      } else {
        setSearchResults([]);
      }
    }, 700);

    // Cleanup the timeout if searchGlobal changes within the debounce delay
    return () => clearTimeout(handler);
  }, [searchGlobal, currentPage, searchFloating]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const searchText = searchFloating || searchGlobal;

  const handleLogoutRedirect = async () => {
    setTokenGlobal(null);
    localStorage.removeItem("login-storage");

    refreshToken(tokenZ.refresh_token, tokenZ.userId, "revokeToken").then(
      (data) => {}
    );

    if (activeAccount) {
      try {
        await instance.logoutRedirect({
          account: activeAccount,
          extraQueryParameters: { login_hint: activeAccount.username },
        });
      } catch (error) {
        console.error("Logout failed:", error);
      }
    } else {
      setTokenGlobal(null);
      localStorage.removeItem("login-storage");
    }
  };

  // console.log(wss, 'wsss')
  useEffect(() => {
    if (wss && wss.action === "updateAshopCategory") {
      if (!wss.changes) return;

      updateBlocks(wss.changes);

      const theData = wss.changes[0]?.data || [];
      const newElement = theData.find((change) => change.isNew === true);

      if (newElement) {
        setDataIsNew(newElement.fileId);
      }
    }

    if (wss && wss.action === "broadcastMessage") {
      const { action, ...rest } = wss;

      setWsGlobal({ ...rest });
    }
  }, [wss]);

  const fetchLastRecent = () => {
    const url = "https://fra-as-api.radio.cloud/v1/lastRecent";

    const params = {
      customer_id: tokenZ.userId,
    };

    fetchData(url, params, tokenZ.access_token)
      .then((data) => {
        setList(data);
      })
      .catch((error) => {
        refreshToken(tokenZ.refresh_token, tokenZ.userId)
          .then((data) => {
            console.log(data, "revoke token");
            const newToken = {
              ...tokenZ,
              ...data,
            };
            setTokenGlobal(newToken);
          })
          .catch((error) => {
            console.error("Failed to refresh token:", error);
            handleLogoutRedirect();
          });
      });
  };

  useEffect(() => {
    if (!tokenZ) return;

    fetchLastRecent();
  }, [tokenZ]);

  const theText = searchGlobal || searchFloating;

  const handleClose = () => {
    setSearchGlobal("");
    setSearchFloating("");
  };

  const [hideSearchbar, setHideSearchbar] = useState(true);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop >= 130) {
        setHideSearchbar(false);
      } else if (scrollTop <= 130) {
        setHideSearchbar(true);
      }
    }, 500); // Throttle every 100ms

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onIdle = () => setIsIdle(true);
  const onActive = () => {
    if (isIdle) {
      fetchFeatured();
      fetchLastRecent();
      fetchWelcome();
      fetchTrending();
      setIsIdle(false);
    }
  };

  useIdleTimer({
    timeout: 1000 * 10,
    onIdle,
    onActive,
  });

  useEffect(() => {
    if (!act) return;

    sendMessage(act);
  }, [act]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const refreshToken = params.get("refresh_token");

    console.log("Email:", email);
    console.log("Refresh Token:", refreshToken);

    if (email) {
      setIsNewspool(true);

      window.history.replaceState(null, "", `${window.location.origin}/`);
    }
  }, []);

  return (
    <div className="min-h-screen px-6">
      <FloatingPlayer
        sLoading={sLoading}
        hideSearchbar={hideSearchbar && !textOnly && !searchText}
      />

      {!searchFloating && (
        <Welcome key={"1"} data={arrOfImages} loading={sLoading} />
      )}

      {/* {(!loadTrending || trending.length > 0) &&
        !searchText &&
        searchResults.length === 0 && (
          <FeaturedCarousel
            headline={"Trending"}
            data={trending}
            type="green"
          />
        )} */}

      {/* {loadTrending && trending.length === 0 && (
        <div className="flex items-center gap-x-2 mb-7">
          {[...Array(4)].map((_, i) => (
            <Skeleton key={i} height="218px" containerClassName="flex-1" />
          ))}
        </div>
      )} */}

      {loadWelcome && data.length === 0 && (
        <div className="flex items-center gap-x-2 mb-7">
          {[...Array(4)].map((_, i) => (
            <Skeleton key={i} height="400px" containerClassName="flex-1" />
          ))}
        </div>
      )}

      {loadWelcome && data.length === 0 && (
        <div className="flex items-center gap-x-2">
          {[...Array(4)].map((_, i) => (
            <Skeleton key={i} height="218px" containerClassName="flex-1" />
          ))}
        </div>
      )}

      {!searchText &&
        searchResults.length === 0 &&
        data.map((item, index) => {
          const cat = item?.category_name || null;

          let layout = item?.block_layout;

          if (Array.isArray(item)) {
            layout = item[0].block_layout;

            let ComponentToRender = MultipleCategories;
            // let ComponentToRender = MultipleCategoriesImg;

            if (layout === 2 && !textOnly) {
              ComponentToRender = MultipleCategoriesImg;
            }

            // if (layout === 4) {
            //     ComponentToRender = Carousel
            // }

            return <ComponentToRender key={index} data={item} />;
          }

          if (cat === "featured") {
            if (textOnly) {
              const data = {
                data: featured,
                category_name: "Featured",
              };
              return <BasicView key={index} data={data} />;
            }
            return <FeaturedCarousel data={featured} key={"featured"} />;
          }

          if (cat === "trending") {
            return (
              <FeaturedCarousel
                headline={"Trending"}
                data={trending}
                type="green"
              />
            );
          }

          if (layout === 1 || textOnly) {
            return <BasicView key={index} data={item} />;
          }

          if (layout === 2) {
            return (
              <FeaturedCarousel
                isNewProp={item?.isNew}
                headline={cat}
                key={cat}
                data={item.data}
              />
            );
          }
          if (layout === 4) {
            return (
              <FeaturedCarousel
                isNewProp={item?.isNew}
                headline={cat}
                key={cat}
                data={item.data}
                type="green"
              />
            );
          }

          // return <Skeleton key={index} height={"160px"} />;
        })}

      {searchResults.length > 0 && (
        <div className="search_res">
          <div className="flex items-center justify-between result">
            <div className="flex items-center">
              <p className="mr-2">{`Anzeige der 50 neuesten Dateneinträge basierend auf der Suche nach: `}</p>
              <p className="keyword">{theText}</p>
            </div>

            <div
              className="flex items-center cursor-pointer"
              onClick={handleClose}
            >
              {/* <i className={`icon-ns ns-chevronLeftGray w-12 h-22 mr-2`}></i>

                                <p>Back to Home</p> */}
              <i
                className={`icon-ns ns-closeGray w-24 h-24 flex-shrink-0 cursor-pointer mr-2`}
              ></i>
            </div>
          </div>
          <RecentList
            type="search"
            list={searchResults}
            key={"443"}
            loading={sLoading}
          />

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}

      {noFound && searchResults.length === 0 && (
        <div
          className={`h-full no_found  flex items-center justify-center flex-col w-full`}
        >
          <div className="search_res w-[75vw]">
            <div className="flex items-center justify-between result">
              <div className="flex items-center">
                <p className="mr-2">{`No results: `}</p>
                <p className="keyword">{theText}</p>
              </div>

              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  setSearchGlobal("");
                  setSearchFloating("");
                }}
              >
                <i className={`icon-ns ns-chevronLeftGray w-12 h-22 mr-2`}></i>

                <p>Back to Home</p>
              </div>
            </div>
          </div>

          <i className={`icon-ns ns-zeroResults w-240 h-240`}></i>
          <p className="big">Keine Ergebnisse gefunden. </p>
          <p className="small">
            Es scheint, dass zu Ihrer Suchanfrage keine Ergebnisse gefunden
            weden konnten.
          </p>
        </div>
      )}

      {show25 && (
        <Modal overlay title={"Die neuesten 25 Meldungen"} onClose={closeModal}>
          <RecentList
            ds="B2"
            catNameProp="Top 25"
            list={list}
            loading={loading}
            key={"1"}
          />
        </Modal>
      )}

      {!searchText && <Footer />}
    </div>
  );
};

export default Homepage;
