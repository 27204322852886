import React, { useRef, useEffect, useState, forwardRef } from "react";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../../hooks/useNavStore";

import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipRemark from "../TooltipRemark/Tooltip";
import { copyToClipboard } from "../../helper/dateRelated";
import { downloadStatistic } from "../../helper/fetchData";
import Spinner from "../Spinner/Spinner";

const RecentPlayer = ({
  dark,
  title,
  url,
  duration,
  date,
  downloadURL,
  image,
  id,
  remark,
  type = "",
  categoryName = null,
  ds = "BM",
  catId,
  filename,
  onKeyDown,
  dataIndex,
  isFirst,
  catName,
  expDate,
  showDesc,
  invalidAvailableAt = false,
}) => {
  const {
    urlFile,
    isPlaying,
    currentDuration,
    totalDuration,
    showPlayer,
    playAudio,
    pauseAudio,
    stopAudio,
    currentAudio,
    isLoading,
    updatedAudios,
    currentFileId,
    handleAudioError,
    downloadAudio,
  } = usePlayerStore();

  const { tokenZ } = useLoginStore();

  const { searchGlobal, searchFloating, emailAddress, setAct } = useDataStore();

  const [loadingType, setLoadingType] = useState(null);

  const params = {
    customer_id: tokenZ.userId,
    cat_id: catId,
    file_id: id,
    packagename: filename,
    title: title,
    trigger: ds,
    user_realm: tokenZ.userId,
  };

  const [showCopy, setShowCopy] = useState(false);

  const checkIsPlaying = (id) => {
    if (!currentAudio) return false;

    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt?.previewAudioURL === currentAudio?.src) return true;
    } else {
      return false;
    }
  };

  const theUrl = (id) => {
    if (!url) return;

    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt) {
        return apt.previewAudioURL;
      } else {
        return url;
      }
    } else {
      return url;
    }
  };

  const titleRef = useRef(null);
  const remarkRef = useRef(null);
  const durationRef = useRef(null);
  const dateRef = useRef(null);
  const downloadRef = useRef(null);
  const playRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // Prevent default Tab behavior

      const focusableElements = [
        titleRef,
        remarkRef,
        durationRef,
        dateRef,
        playRef,
        downloadRef, // Update focusable order
      ].filter((el) => el?.current); // Ensure all refs are valid

      const currentIndex = focusableElements.findIndex(
        (el) => el.current === document.activeElement
      );

      const nextIndex = event.shiftKey
        ? (currentIndex - 1 + focusableElements.length) %
          focusableElements.length // Shift+Tab: Move backward
        : (currentIndex + 1) % focusableElements.length; // Tab: Move forward

      if (!event.shiftKey && currentIndex === focusableElements.length - 1) {
        // If on the last element and pressing Tab, move to the next title
        const nextTitle = document.querySelector(
          `[data-index="${dataIndex + 1}"]`
        );
        nextTitle?.focus();
      } else if (event.shiftKey && currentIndex === 0) {
        // If on the first element and pressing Shift+Tab, move to the previous title
        const prevTitle = document.querySelector(
          `[data-index="${dataIndex - 1}"]`
        );
        prevTitle?.focus();
      } else {
        focusableElements[nextIndex]?.current?.focus();
      }
    }
  };

  useEffect(() => {
    if (searchFloating || searchGlobal) return;
    if (isFirst && titleRef.current) {
      titleRef.current.focus();
    }
  }, [isFirst, searchFloating, searchGlobal]);

  useEffect(() => {
    const handleFocusChange = () => {
      const focusedElement = document.activeElement;

      // Check if the focused element is not the button

      if (+currentFileId === +id) {
        // console.log('Currently focused element:', focusedElement);
        if (isPlaying && playRef.current !== focusedElement) {
          console.log("Pausing audio because focus is not on the button.");

          playAudio(theUrl(currentFileId), currentFileId, title);
          setLoadingType("play");
        }
      }
    };

    // Add event listener for focus changes
    document.addEventListener("focusin", handleFocusChange);

    // Cleanup event listener
    return () => {
      document.removeEventListener("focusin", handleFocusChange);
    };
  }, [isPlaying, currentFileId]);

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    setAct(JSON.stringify(data));
  };

  const infoActivity = `${catName} ${title}`;

  const isAktuelle = catName === "Aktuelles Angebot";

  const getText = (invalidAvailableAt) => {
    if (url) return null;
    if (invalidAvailableAt) {
      return <p className="flex-1 vorr line-clamp-1">Abrufbar in Kürze</p>;
    } else {
      return <p className="flex-1 vorr line-clamp-1">Vorr. abrufbar ab:</p>;
    }
  };

  return (
    <>
      <div
        className={`recent_player ${dark ? "dark" : ""} ${type} ${
          !showDesc ? "four" : ""
        }`}
        tabIndex={-1} // Prevent the whole container from being focusable
        onKeyDown={handleKeyDown}
      >
        <div className="flex">
          <button
            className="player_container cursor-pointer flex justify-center items-center"
            onClick={() => {
              playAudio(theUrl(id), id, title);
              setLoadingType("play");

              activityWs(
                `${
                  isPlaying && (currentAudio?.src === url || checkIsPlaying(id))
                    ? `Pause: ${infoActivity}`
                    : `Play: ${infoActivity}`
                }`
              );
            }}
            tabIndex={0}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") {
                evt.preventDefault(); // Prevent default behavior like button submission

                playAudio(theUrl(id), id, title);
                setLoadingType("play");

                activityWs(
                  `${
                    isPlaying &&
                    (currentAudio?.src === url || checkIsPlaying(id))
                      ? `Pause: ${infoActivity}`
                      : `Play: ${infoActivity}`
                  }`
                );

                return;
              }

              onKeyDown(evt);
            }}
            role="button" // Explicitly defines this as a button for screen readers
            aria-label={
              isLoading
                ? "Loading audio"
                : isPlaying && (currentAudio?.src === url || checkIsPlaying(id))
                ? `Pause: ${infoActivity}`
                : `Play: ${infoActivity}`
            }
            ref={playRef}
          >
            {+isLoading === +id && loadingType === "play" ? (
              <Spinner />
            ) : (
              <i
                className={`icon-ns ${
                  isPlaying && (currentAudio?.src === url || checkIsPlaying(id))
                    ? "ns-stopGreen"
                    : !url
                    ? "ns-playGray"
                    : "ns-playGreen"
                } w-24 h-24`}
              ></i>
            )}
          </button>

          {image && (
            <img
              src={image}
              className="img_recent"
              alt=""
              id={`tooltip-type-${id}`}
            />
          )}
        </div>

        <p
          tabIndex={0}
          aria-label={title}
          ref={titleRef}
          className="title_r line-clamp-1"
          onKeyDown={onKeyDown} // Pass the keydown event handler
          data-index={dataIndex} // Add data-index for navigation
        >
          {title}
        </p>

        {showDesc && (
          <div
            className="flex items-center mr-3"
            id={`tooltip-remak-${id}`}
            onClick={() => {
              setShowCopy(id);
              // activityWs(`Read Text: ${infoActivity}`);
            }}
            onMouseLeave={() => setShowCopy(null)}
            tabIndex={0}
            aria-label={remark}
            ref={remarkRef}
            onKeyDown={onKeyDown} // Pass the keydown event handler
          >
            <p className="sub_title line-clamp-1">{remark}</p>

            {showCopy === id && (
              <i
                className={`icon-ns flex-shrink-0 ns-copyRectangle w-20 h-20 cursor-pointer`}
                onClick={() => {
                  copyToClipboard(title, remark);
                  activityWs(`Copy Text: ${infoActivity}`);
                }}
              ></i>
            )}
          </div>
        )}

        {/* <div className="grid grid-cols-12 title_container">

              </div> */}

        <p
          ref={durationRef}
          tabIndex={0}
          aria-label={duration}
          className="duration_as"
          onKeyDown={onKeyDown} // Pass the keydown event handler
        >
          {duration}
        </p>

        {categoryName && (
          <p
            ref={dateRef}
            tabIndex={0}
            aria-label={date}
            className="date"
            onKeyDown={onKeyDown} // Pass the keydown event handler
          >
            {date}
          </p>
        )}

        {!categoryName && (
          <div className="flex justify-between items-center pr-2">
            <button
              tabIndex={0}
              aria-label={date}
              ref={dateRef}
              className="date"
              onKeyDown={onKeyDown} // Pass the keydown event handler
            >
              <div>{getText(invalidAvailableAt)}</div>

              {!invalidAvailableAt && <p>{date}</p>}
            </button>

            <button
              onClick={() => {
                downloadAudio(id, params, downloadURL, expDate);
                setLoadingType("download");
                activityWs(`Download: ${infoActivity}`);
              }} // Trigger audio download on click
              tabIndex={0}
              onKeyDown={(evt) => {
                if (evt.key === "Enter") {
                  evt.preventDefault(); // Prevent default behavior like button submission

                  downloadAudio(id, params, downloadURL, expDate);
                  setLoadingType("download");

                  activityWs(`Download: ${infoActivity}`);
                }
              }}
              ref={downloadRef}
              aria-label="Download"
              className="w-5 h-5"
            >
              {+isLoading === +id && loadingType === "download" ? (
                <div className="w-5 h-5 flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <i
                  className={`icon-ns cursor-pointer ns-download${
                    !downloadURL ? "Gray" : "Green"
                  } w-18 h-18`}
                ></i>
              )}
            </button>
          </div>
        )}

        {categoryName && (
          <div className="flex justify-between items-center pr-2">
            <p className="date">{categoryName}</p>

            <button
              onClick={() => {
                downloadAudio(id, params, downloadURL, expDate);
                activityWs(`Download: ${infoActivity}`);
                setLoadingType("download");
              }} // Trigger audio download on click
              tabIndex={0}
              onKeyDown={(evt) => {
                if (evt.key === "Enter") {
                  evt.preventDefault(); // Prevent default behavior like button submission

                  downloadAudio(id, params, downloadURL, expDate);
                  activityWs(`Download: ${infoActivity}`);
                }
              }}
              ref={downloadRef}
              aria-label="Download"
            >
              {+isLoading === +id && loadingType === "download" ? (
                <div className="w-5 h-5 flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <i
                  className={`icon-ns cursor-pointer ns-download${
                    !downloadURL ? "Gray" : "Green"
                  } w-18 h-18`}
                ></i>
              )}
            </button>
          </div>
        )}
        <ReactTooltip
          anchorSelect={`#tooltip-remak-${id}`}
          style={{
            zIndex: 99,
            padding: 0,
            backgroundColor: "#eaeded",
            color: "#333",
            borderRadius: 12,
            background: "#fff",
            boxShadow: "0px 4px 11px 3px rgba(0, 0, 0, 0.22)",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
          openOnClick
          closeEvents={{
            mouseleave: true,
          }}
          place="right"
          // variant="info"
          // className="tooltip-computer"
          noArrow
          clickable
          opacity={1}
          content={<TooltipRemark title={title} text={remark} />}
        />
      </div>

      {isAktuelle && !showDesc && (
        <div className={`desc_hidden ${dark ? "dark" : ""}`}>
          <p className="title_desc">{remark}</p>
        </div>
      )}
    </>
  );
};

export default RecentPlayer;
